<template>
  <div class="daheDetail fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">详情</span>
      <el-form
        inline
        v-model="formInline"
        class="right themed-form search-form"
      >
        <el-form-item label="下单时间:">
          <el-date-picker
            style="width: 100%"
            v-model="formInline.startTime"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="套餐到期时间:">
          <el-date-picker
            style="width: 100%"
            v-model="formInline.endTime"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="分销商:">
          <el-input v-model="formInline.agentThreeName"></el-input>
        </el-form-item>
        <el-form-item label="卡状态:">
          <el-select v-model="formInline.status" filterable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in statusArr"
              :key="item.id"
              :label="item.statusName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="ICCID号">
          <el-input v-model="formInline.iccidMark"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >搜索查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="exportExcel"
            class="themed-button"
            icon="el-icon-caret-left"
            >导出excel</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="iccidMark" label="ICCID"> </el-table-column>
        <el-table-column prop="agentName" label="代理商名称"> </el-table-column>
        <el-table-column prop="createTime" label="下单时间"> </el-table-column>
        <el-table-column prop="expireDate" label="套餐到期时间">
        </el-table-column>
        <el-table-column prop="useFlow" label="用量"> </el-table-column>
        <el-table-column prop="statusStr" label="卡状态"> </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageNo"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import loading from "@/utils/Loading.js";
import onresize from "@/mixins/resize.js";
import { daheAgentDetailList,daheExport } from "@/api/staticsManagement.js";
export default {
  name:'daheDetail',
  mixins: [onresize],
  data() {
    return {
      formInline: {
        id: "",
        startTime: "",
        endTime: "",
        flowSize: "",
        agentId: "",
        agentThreeName: "",
        status: "",
        iccidMark: "",
      },
      pageNo: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      statusArr: [
        { id: "1", statusName: "待激活" },
        { id: "2", statusName: "正常" },
        { id: "3", statusName: "断网" },
        { id: "4", statusName: "停机" },
        { id: "5", statusName: "异常" },
      ],
    };
  },
  created() {
    this.formInline.startTime = this.$route.query.startTime;
    this.formInline.endTime = this.$route.query.endTime;
    this.formInline.agentId = this.$route.query.agentId;
    if (
      this.$route.query.flowSize != "undefined" &&
      this.$route.query.flowSize != ""
    ) {
     this.formInline.flowSize = this.$route.query.flowSize;
    }
   
    
   
    this.getDaheDetailsList(this.formInline);
  },
  methods: {
    getDaheDetailsList(params) {
      daheAgentDetailList(params).then((res) => {
        this.total = res.total;
        this.pageNo = res.pageNo;
        this.pageSize = res.pageSize;
        this.tableData = res.rows;
      });
    },
    onSearch() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };
      Object.assign(params, this.formInline);
      this.getDaheDetailsList(params);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };
      Object.assign(params, this.formInline);
      this.getDaheDetailsList(params);
    },
    onPageChange(page) {
      this.pageNo = page;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };
      Object.assign(params, this.formInline);
      this.getDaheDetailsList(params);
    },
    exportExcel(){
    loading.show()
    /* ../../statis/dahe/agentOrderList.do?id=&flowSize=&agentId=F1A1A9456F4D451585B4B5FC83A129DF&startTime=&endTime=&agentThreeName=&status=&iccidMark= */
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page
				}
				Object.assign(params, this.formInline)
				daheExport(params).then(res => {
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
							confirmButtonText: '确定',
						}).then(() => {
							this.$router.push('/tob/i_export_list')
						});
					} else {
						this.$message({
							showClose: true,
							message: res.system_result_message_key,
							type: 'error'
						});
					}
				})
    },
  },
};
</script>
<style >
.daheDetail  .el-form-item__label{
  vertical-align: top;
}
</style>